import './App.css';
import bgVideo from './assets/backgroundvideo.mp4';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from 'react-router-dom';
import Navbar from "./component/Navbar";
import About from "./pages/about";
import Contact from "./pages/contact";
import Faq from "./pages/faq";
import { RandomReveal } from "react-random-reveal";

function App() {
  const [isPlaying, setIsPlaying] = React.useState(true);
  React.useEffect(() => {
    document.body.onkeyup = function (e) {
      if (e.keyCode === 32) {
        setIsPlaying((playing) => !playing);
      }
    };
  }, []);
  return (
  <>

    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color:'#00ffff',
      }}
    >
    <span className="adjectives">
        <RandomReveal
              isPlaying={isPlaying}
              duration={Infinity}
              revealDuration={0}
              updateInterval={0.8}
              characterSet={[
                "Machine Learning",
                "Data Management",
                "Digital Transformation",
                "Microlearning"
                ]}
              characters=" "
            />
        </span>
    </div>
    <div className="App">
      <video
        autoPlay
        muted
        loop
        style={{
          position:"absolute",
          width:"100%",
          left:"50%",
          top:"50%",
          height:"100%",
          objectFit:"cover",
          transform:"translate(-50%, -50%",
          zIndex:"-1",
          opacity:"100%"
        }}
        src={bgVideo} type="video/mp4" />
       
      </div>
      <Router>
        <Navbar />

        <Routes style={{ backgroundColor: "hsl(0, 100%, 94%)" }}>
          <Route path='/about' component={About} />
          <Route path='/contact' component = {Contact} />
          <Route path="/faq" component={Faq} />
      </Routes>
      <div className='bg_image'></div>
    </Router>
    </>
  );
  
}

export default App;